import * as React from "react";

import "./klaro.css";

const Analytics = () => {

  const script = `
  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

      ga('create', 'UA-37531010-1', 'auto');
      ga('set', 'anonymizeIp', true); 
      ga('send', 'pageview');
  `;

  return (
    <>
      <script defer type="application/javascript" src="/klaro/config.js"/>
      <script defer type="application/javascript" src="/klaro/klaro-no-css.js"/>
      <script
        type="text/plain"
        data-type="application/javascript"
        data-name="google-analytics"
        dangerouslySetInnerHTML={{__html: script}}
      />
    </>
  );
}

export default Analytics;
